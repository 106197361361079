import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Box } from "./Core";
import { device } from "../utils";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const CaseCardStyled = styled(Box)`
  width: 100%;
  transition: 0.4s;
  border-radius: 8px;

  .img-container {
    position: relative;
    z-index: 1;
    img {
      border-radius: 8px 8px 0 0;
      max-width: 100%;
    }
  }

  &:hover {
    transform: translateY(-20px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  }
  &:hover i {
    transform: translateX(10px);
    opacity: 1;
  }
`;

const TextContent = styled(Box)`
  position: relative;
  z-index: 1;
  transition: 0.4s;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 12px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  @media ${device.sm} {
    padding-top: 22px;
    padding-bottom: 20px;
    padding-left: 38px;
    padding-right: 38px;
  }
`;

const Shape = styled(Box)`
  background: initial;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  bottom: 93%;
  @media ${device.sm} {
    bottom: 94%;
  }
  @media ${device.xl} {
    bottom: 95%;
  }
  svg {
    width: 100%;
    path {
      fill: ${({ theme, bg }) => theme.colors[bg]};
      transition: 0.4s;
    }
  }
`;

// const PreTitle = styled(Text)`
//   font-size: 16px;
//   font-weight: 300;
//   letter-spacing: -0.5px;
//   line-height: 28px;
// `;

const TitleStyled = styled(Title)`
  letter-spacing: -1.06px;
  margin-bottom: 22px;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  @media ${device.sm} {
    font-size: 34px;
    font-weight: 700;
  }
`;

const CaseCard = ({
  isDark = true,
  bg = "secondary",
  img,
  title = "",
  url,
}) => {
  return (
    <Link to={url}>
      <CaseCardStyled>
        <div className="img-container">
          <PreviewCompatibleImage
            imageInfo={{
              image: img,
            }}
            style={{ height: 200 }}
          />
        </div>
        <TextContent bg={bg}>
          <Shape bg={bg}>
            <svg height="22" viewBox="0 0 540 22">
              <g>
                <g>
                  <path d="M0 0s233.088 28.458 539.999 0c306.91-28.458 0 22 0 22H.06"></path>
                </g>
              </g>
            </svg>
          </Shape>
          {/* <PreTitle color={isDark ? "lightShade" : "darkShade"}>{meta}</PreTitle> */}
          <TitleStyled color={isDark ? "light" : "dark"}>{title}</TitleStyled>
          {/* <Text color={isDark ? "lightShade" : "darkShade"}>{children}</Text> */}
        </TextContent>
      </CaseCardStyled>
    </Link>
  );
};

const SportsRoll = (props) => {
  const { edges: sports } = props.data.allMarkdownRemark;

  return (
    <Box bg="light">
      <Container>
        <Row data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
          {sports.map(({ node: sport }) => (
            <Col lg="4" md="6" className="mb-4" key={sport.id}>
              <CaseCard
                img={sport.frontmatter.sport_image}
                title={sport.frontmatter.title}
                url={sport.fields.slug}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Box>
  );
};

export default SportsRoll;
